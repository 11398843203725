<template>
  <!--begin::Card-->
  <!--Card with image, and below it, a title of product-->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Image-->
      <div class="overlay">
        <div class="overlay-wrapper rounded-top rounded-top d-flex justify-content-center align-items-center fix-height">
          <img
            :src="imgUri"
            class="card-img-top rounded-top fixed-sizes"
            alt="..."
          />
        </div>
        <div class="overlay-layer rounded-top" @click="openImage" style="cursor: pointer">
          <!--ON OVERLAY SET AN INPUT FOR QTT-->
          <div class="overlay-layer-content">
            <div class="d-flex align-items-center h-100">
              <div class="d-flex flex-column align-items-center justify-content-center w-100">
                <div class="d-flex align-items-center mx-2" @click.stop>
                  <input
                    type="number"
                    class="form-control form-control-sm me-2"
                    min="1"
                    v-model="quantity"
                  />
                  <button
                    class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase"
                    @click="addProduct(item)"
                  >
                    <i class="fas fa-cart-plus p-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="item.documento" class="d-flex align-items-center h-100" style="position: relative;">
              <div class="d-flex flex-column align-items-center justify-content-center w-100">
                <div class="d-flex align-items-center mt-2" @click.stop>
                  <button
                    class="btn btn-sm btn-light-primary font-weight-bolder text-uppercase"
                    @click="downloadPDF()"
                  >
                    <i class="fas fa-file-pdf p-0"></i>
                  </button>
                </div>
              </div>
            </div>
            </div>

<!--          <a-->
<!--            :href="item.strImagem"-->
<!--            class="btn btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
<!--            data-fancybox="gallery"-->
<!--          >-->
<!--            <i class="ki ki-plus icon-1x"></i>-->
<!--          </a>-->
        </div>
      </div>
      <!--end::Image-->
      <!--begin::Info-->
      <div class="p-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="mr-3">
            <!--begin::Name-->
            <label
                class="d-flex align-items-center text-dark fs-8"
            >
            <span><span class="fw-bolder">{{ item.strCodigo }}</span> {{ " - " + item.strDescricao + (item.strAbreviatura ? " " + item.strAbreviatura : "") }}</span>
            </label>
            <!--end::Name-->
          </div>
        </div>
      </div>
      <!--end::Info-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Card-->

</template>

<script>
import fs from "fs";
import path from "path";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "ProductCard",
  data() {
    return {
      quantity: 1,
      imgUrl: "",
    };
  },
  props: {
    item: Object,
  },
  emits: ["addProduct", "downloadPDF"],
  computed: {
    imgUri() {
      if (this.imgUrl) {
        return this.imgUrl;
      } else {
        return require('@/assets/products/default-img.gif');
      }
    },
  },
  watch: {
    item: {
      handler: function (newVal, oldVal) {
        this.imgUrl = "";
        this.image();
      },
      deep: true,
    },
  },
  methods: {
    addProduct() {
      if(this.quantity) {
        this.$emit("addProduct", this.item, this.quantity);
      }
    },
    downloadPDF() {
      this.$emit("downloadPDF", this.item.documento);
    },
    image() {
      //let imageUrl = `${this.item.imageUrl}`
      //TODO-> GET IMAGES FROM REAL SERVER
      let imageUrl = `https://portal.ferragensdomarques.pt/store/${this.item.strCodigo}.png`
      let image = new Image()
      image.src = imageUrl
      //wait for image to load
      image.onload = () => {
        this.imgUrl = imageUrl
      }
    },
    openImage() {
      if (!this.imgUrl) return;

      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        imageUrl: this.imgUrl,
        imageAlt: this.item,
        showCloseButton: true,
        padding: "1.5rem 0 0 0",
      });
      /* 
      // Open pdf in modal -- beta
      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        width: '100%',
        height: '100%',
        html: `
          <object
            style="width:100%;height:100rem;max-height:100rem;"
            data="https://www.africau.edu/images/default/sample.pdf"
            type="application/pdf"
          ></object>
        `,        
        showCloseButton: true,
        padding: '0',
      }); */
    },
  },
  mounted() {
     this.image()
  },
}
</script>

<style scoped>

.fixed-sizes {
  max-width: 12.027vw;
  max-height: 7.396vw;
  width: auto;
}
.fix-height {
  min-height: 7.396vw;
}

@media screen and (max-width: 1400px) { 
  .fixed-sizes {
    max-width: 11.507vw;
    max-height: 6.903vw;
    width: auto;
  }
  .fix-height {
    min-height: 6.903vw;
  }
}

@media screen and (max-width: 1200px) { 
  .fixed-sizes {
    max-width: 16.204vw;
    max-height: 9.962vw;
    width: auto;
  }
  .fix-height {
    min-height: 9.962vw;
  }
}

@media screen and (max-width: 992px) { 
  .fixed-sizes {
    max-width: 29vw;
    max-height: 17vw;
    width: auto;
  }
  .fix-height {
    min-height: 17vw;
  }
}

@media screen and (max-width: 768px) { 
  .fixed-sizes {
    max-width: 43vw;
    max-height: 26vw;
    width: auto;
  }
  .fix-height {
    min-height: 26vw;
  }
}

@media screen and (max-width: 576px) { 
  .fixed-sizes {
    max-width: 84vw;
    max-height: 50.448vw;
    width: auto;
  }
  .fix-height {
    min-height: 50.448vw;
  }
}

</style>