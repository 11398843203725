<template>
  <Catalog
    :list="list"
    :pageProp="page"
    :pages="pages"
    :itemsPerPage="itemsPerPage"
    :totalItems="totalItems"
    :showFilters="true"
    :categories="families"
    :filterWithDoc="filterWithDoc"
    :loading="loading"
    @addProduct="addProduct"
    @updateFetchPage="updateFetchPage"
    @paginatorHandler="paginatorHandler"
    @queryForProduct="queryForProduct"
    @downloadPDF="downloadPDF"
    @updateCatalog="updateCatalog"
    @filterWithDocHandler="filterWithDocHandler"
  />
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Catalog from "@/components/Products/Catalog.vue";
import ApiService from "@/core/services/ApiService";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import store from "@/store";

export default {
  name: "Products",
  components: {
    Catalog,
  },
  data() {
    return {
      list: [],
      page: 1,
      pages: 1,
      itemsPerPage: 24,
      totalItems: 0,
      query: "",
      families: [],
      catalogFilter: [],
      filterWithDoc: true,
      loading: false,
    };
  },

  methods: {
    loadProducts() {
      if(this.loading) return;
      this.loading = true;

      ApiService.get(`/artigos` + this.getQuery())
        .then((response) => {
          this.list = response.data.Data;
          this.totalItems = response.data.TotalPages;
          this.pages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    loadFamilies() {
      ApiService.get(`artigos/familias/`)
        .then((response) => {
          this.families = response.data.Data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadPDF(docId) {
      //call api, get file in b64 format, and download it
      ApiService.get("Utils/document/" + docId).then((response) => {
        let file = response.data.Data.docBase64;
        let fileName = response.data.Data.docName;
        const parts = fileName.split(".");
        let fileExtension = parts[parts.length - 1];
        let mimeType = "application/pdf";
        if (fileExtension === "xml") {
          mimeType = "application/xml";
        }
        //if txt
        else if (fileExtension === "txt") {
          mimeType = "text/plain";
        }

        let byteCharacters = atob(file);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: mimeType });

        // Create a URL for the Blob
        let fileURL = URL.createObjectURL(blob);

        // Open the file in a new tab
        let newTab = window.open(fileURL, "_blank");
        if (newTab) {
          newTab.document.title = fileName;
        } else {
          // Opening the new tab was blocked by the browser
          console.error("Opening the file was blocked by the browser.");
          window.location.href = fileURL;
        }

        /* let fileB64 = "data:" + mimeType + ";base64," + file;
        let link = document.createElement("a");
        link.href = fileB64;

        link.download = fileName;
        link.click(); */

      });
    },

    updateFetchPage(page, pagesSize) {
      this.page = page;
      this.itemsPerPage = pagesSize;
      this.loadProducts();
    },

    updateCatalog(catalog) {
      this.catalogFilter = catalog;
      this.loadProducts();
    },

    paginatorHandler(page) {
      this.page = page;
      this.loadProducts();
    },

    queryForProduct(query) {
      this.query = query.searchInput;
      this.page = 1;
      this.loadProducts();
    },

    filterWithDocHandler() {
      this.filterWithDoc = !this.filterWithDoc;
      this.loadProducts(); 
    },

    addProduct(item, quantity) {
      if(quantity < 1) {
        quantity = 1;
      } else {
        quantity = Math.ceil(quantity);
      } 
      store.commit("addProductToCartNoMap", [
        {
          strCodArtigo: item.strCodigo,
          strDescricao: item.strDescricao,
          fltQuantidade: quantity,
          strAbreviatura: item.strAbreviatura,
          tipoStock: item.tipoStock,
        },
      ]);

      toast.success("Artigo adicionado", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: "slide",
        theme: "colored",
        type: "success",
      });
    },

    getQuery() {
      let queryString = `?pageNumber=${this.page}&pageSize=${this.itemsPerPage}&bitInactivo=false&filterWithDoc=${this.filterWithDoc}`;
      if (this.query) {
        queryString += `&strCodArtigo=${this.query}&strDescricao=${this.query}&strAbreviatura=${this.query}`;
      }
      if (this.catalogFilter) {
        //for each level add query, for example: ?famLevel1=1&famLevel2=1&famLevel3=1 ...
        //catalofFilter is an object with level1, level2, level3, level4, level5
        for (let i = 1; i <= 5; i++) {
          if (this.catalogFilter[`Level${i}`]) {
            queryString += `&famLevel${i}=${this.catalogFilter[`Level${i}`]}`;
          }
        }
      }
      return queryString;
    },

  },
  mounted() {
    setCurrentPageBreadcrumbs("Catálogo");
    this.loadProducts();
    this.loadFamilies();
  },
};
</script>

<style scoped></style>
