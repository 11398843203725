<template>
  <div class="card row" style="border-radius: 0.5px">
      <CustomTableTopFilter
          :filterStatus="[]"
          :pageProp="pageProp"
          tableName=""
          :pages="pages"
          :showFilters="showFilters"
          :showDatesFilters="false"
          :show-catalog-filter="true"
          :categories="categories"
          :showOnlyProdsWithDocsFilter="true"
          :filterWithDoc="filterWithDoc"
          :loading="loading"
          @paginatorHandler="paginatorHandler"
          @queryForOccurences="queryForProduct"
          @updateCatalog="updateCatalog"
          @filterWithDocHandler="filterWithDocHandler"
      ></CustomTableTopFilter>

    <div class="card-body mb-5 py-3">
      <!--begin cards-->
      <div class="row">
        <template v-if="loading && list.length == 0"
          ><tr v-for="r in 15" :key="r" style="height: 38px"></tr
        ></template>
        <template v-for="(item, index) in list" :key="index">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <!--begin::Card-->
            <ProductCard
                :item="item"
                @addProduct="addProduct"
                @downloadPDF="downloadPDF"
            />
            <!--end::Card-->
          </div>
        </template>
      </div>
      <!--end cards-->
    </div>

    <CustomTableBottomPagination 
      :pageProp="pageProp"
      :pages="pages"
      :itemsPerPage="itemsPerPage"
      :totalItems="totalItems"
      :pagesOptions="[12,18,24,36, 48, 60]"
      :defaultPageSize="24"
      @updateFetchPage="updateFetchPage"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus">
    </CustomTableBottomPagination>

    <transition name="loader">
      <div v-if="loading" class="overlay-loader">
        <div class="text-center">
          <span
            class="spinner-border spinner-border-lg custom-loader align-middle my-2 text-primary"
            role="status"
          ></span
          ><br /><span class="text-white fs-4 fw-bold visually-hidden"
            >A carregar...</span
          >
        </div>
      </div>
    </transition>

  </div>

</template>

<script>
import ProductCard from "@/components/Products/ProductCard.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import CustomTableBottomPagination from "@/components/customTables/CustomTableBottomPagination.vue";

export default {
  name: "Catalog",
  components: {ProductCard, CustomTableTopFilter, CustomTableBottomPagination},
  props: {
    list: Array,
    pageProp: Number,
    pages: Number,
    itemsPerPage: Number,
    totalItems: Number,
    showFilters: Boolean,
    categories: Array,
    filterWithDoc: Boolean,
    loading: Boolean,
  },
  emits: ["addProduct", "paginatorHandler", "queryForProduct", "downloadPDF", "updateCatalog", "filterWithDocHandler","updateFetchPage",],
  methods: {
    updateFetchPage(page, pagesSize) {
      this.$emit("updateFetchPage", page, pagesSize);
    },
    addProduct(item, quantity) {
      this.$emit("addProduct", item, quantity);
    },
    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    queryForProduct(query) {
      this.$emit("queryForProduct", query);
    },
    downloadPDF(docId) {
      this.$emit("downloadPDF", docId);
    },
    updateCatalog(catalog) {
      this.$emit("updateCatalog", catalog);
    },
    filterWithDocHandler() {
      this.$emit("filterWithDocHandler");
    },
  },
}
</script>

<style scoped>

</style>